
import { Component, Vue, Mixins, Prop } from "vue-property-decorator";
// 混入
import Loading from "@/mixins/loading";
import tablePagination from "@/mixins/tablePagination";
// api
import { getResource, deleteResource } from "@/api/resource";
import { Resource } from "@/types/api/resource";

@Component
export default class Table extends Mixins(Loading, tablePagination) {
  @Prop() urlKeyword!: string;
  @Prop() nameKeyword!: string;
  @Prop() categoryId!: number;

  private tableData: Array<Resource> = [];

  mounted() {
    this.getData();
  }

  /**
   * methods
   */
  getData() {
    this.showLoading();
    getResource({
      pageNum: this.currentPage,
      pageSize: this.pageSize,
      categoryId: this.categoryId,
      nameKeyword: this.nameKeyword,
      urlKeyword: this.urlKeyword,
    }).then((res: any) => {
      this.tableData = res.data.list;
      this.total = res.data.total;
      this.hideLoading();
    });
  }
  // 删除
  handleDelete(row: any) {
    this.$confirm(`是否要删除资源编号 ${row.id} ?`, "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(() => {
      deleteResource({
        id: row.id,
      }).then((res: any) => {
        this.getData();
        this.$message.success("删除成功");
      });
    });
  }
}

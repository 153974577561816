
import { Component, Vue, Mixins, Prop } from "vue-property-decorator";
// api
import { ResourceCategory } from "@/types/api/resource";
@Component
export default class Header extends Vue {
  @Prop() urlKeyword!: string;
  @Prop() nameKeyword!: string;
  @Prop() categoryId!: number;
  @Prop() resourceCategoryAll!: Array<ResourceCategory>;
}

import { service } from '@/ajax/request'
import { Id } from '@/types/common'
import { Resource, ResourceList, ResourceCategory } from '@/types/api/resource'

// 添加后台资源
export const createResource = (params: Resource) => (
  service({
    url: '/resource/create',
    method: 'POST',
    params
  })
)

// 根据ID删除后台资源
export const deleteResource = (params: Id) => (
  service({
    url: `/resource/delete/${params.id}`,
    method: 'POST',
    params,
    type: 'form'
  })
)

// 分页模糊查询后台资源
export const getResource = (params: ResourceList) => (
  service({
    url: `/resource/list`,
    method: 'get',
    params,
    type: 'form'
  })
)

// 查询所有后台资源
export const getResourceAll = () => (
  service({
    url: `/resource/listAll`,
    method: 'get',
    type: 'form'
  })
)

// 修改后台资源
export const updateResource = (params: Resource) => (
  service({
    url: `/resource/update/${params.id}`,
    method: 'post',
    params
  })
)

// 根据ID获取资源详情
export const getResourceById = (params: Id) => (
  service({
    url: `/resource/${params.id}`,
    method: 'get',
    type: 'form',
    params
  })
)

// 添加后台资源分类
export const createResourceCategory = (params: ResourceCategory) => (
  service({
    url: `/resourceCategory/create`,
    method: 'POST',
    params
  })
)

// 删除后台资源分类
export const deleteResourceCategory = (params: Id) => (
  service({
    url: `/resourceCategory/delete/${params.id}`,
    method: 'POST',
    params,
    type: 'form'
  })
)

// 获取所有后台资源分类
export const getResourceCategoryAll = () => (
  service({
    url: `/resourceCategory/listAll`,
    method: 'get',
    type: 'form'
  })
)

// 修改后台资源分类
export const updateResourceCategory = (params: ResourceCategory) => (
  service({
    url: `/resourceCategory/update/${params.id}`,
    method: 'post',
    params
  })
)

import { Component, Vue, Mixins, Prop } from "vue-property-decorator";
// 混入
import Loading from "@/mixins/loading";
// api
import {
  getResource,
  getResourceById,
  getResourceCategoryAll,
} from "@/api/resource";
import { Resource, ResourceCategory } from "@/types/api/resource";
// 组件
import Table from "./components/Table.vue";
import Header from "./components/Header.vue";
import Dialog from "./components/Dialog.vue";
// 工具
import copy from "@/utils/deepCopy";
import debounce from '@/utils/debounce';

@Component({
  components: {
    Table,
    Header,
    Dialog,
  },
})
export default class ResourcePage extends Mixins(Loading) {
  dialogVisible: boolean = false;
  urlKeyword: string = "";
  nameKeyword: string = "";
  categoryId: number | string = "";
  type: string = "add";
  initForm: Resource = {
    categoryId: undefined,
    createTime: "",
    description: "",
    id: 0,
    name: "",
    url: "",
  };
  form: Resource = this.initForm;
  resourceCategoryAll: Array<ResourceCategory> = []; // 资源分类
  resourceCategoryAll1: Array<ResourceCategory> = []; // 资源分类

  mounted() {
    this.getResourceCategoryAll_();
  }

  // 输入内容
  inputValue(val: string, key: string) {
    let self: any = this;
    self[key] = val;
    debounce(() => this.getData());
  }
  // 添加
  handleAdd() {
    this.type = "add";
    this.form = copy(this.initForm);
    this.dialogVisible = true;
    this.getResourceCategoryAll_();
  }
  // 编辑
  handleEdit(row: Resource) {
    this.showLoading();
    getResourceById({ id: row.id }).then((res: any) => {
      this.type = "edit";
      this.form = res.data;
      this.hideLoading();
      this.dialogVisible = true;
      this.getResourceCategoryAll_();
    });
  }
  // 获取数据
  getData() {
    let table: any = this.$refs.table;
    table.getData();
  }
  // 获取资源分类
  getResourceCategoryAll_() {
    getResourceCategoryAll().then((res: any) => {
      this.resourceCategoryAll = res.data;
      this.resourceCategoryAll1 = copy(res.data);
      this.resourceCategoryAll1.unshift({
        id: "",
        name: "全部",
        sort: 0,
      });
    });
  }
}


import { Component, Vue, Mixins, Prop } from "vue-property-decorator";
// api
import { createResource, updateResource } from "@/api/resource";
import { Resource, ResourceCategory } from "@/types/api/resource";
// 工具
import print from "@/utils/print";

@Component
export default class Table extends Vue {
  @Prop() dialogVisible!: boolean;
  @Prop() form!: Resource;
  @Prop() type!: string;
  @Prop() resourceCategoryAll!: Array<ResourceCategory>;

  ruleForm: string = "ruleForm";
  // 规则
  rules: any = {
    name: [
      { required: true, message: "请输入资源名称", trigger: "blur" },
      {
        min: 1,
        max: 20,
        message: "长度在 1 到 10 个字符",
        trigger: "blur",
      },
    ],
  };

  mounted() {}

  // 关闭对话框
  close() {
    this.$emit("close");
  }
  // 确认提交
  confirm() {
    let ref: any = this.$refs;
    ref[this.ruleForm].validate((valid: any) => {
      // 校验成功
      if (valid) {
        let prom: any;
        if (this.type == "add") {
          prom = createResource(this.form); // 添加
        } else {
          prom = updateResource(this.form); // 修改
        }
        prom.then((res: any) => {
          this.$message.success("提交成功");
          this.$emit("getData");
          this.close();
        });
      } else {
        return false;
      }
    });
  }
}
